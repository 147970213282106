import {
  IconBrengerB,
  IconBrengerLogo,
  IconChat,
  IconDoubleLeftCarret,
  IconHorizontalDots,
  IconMaps,
  IconSearch,
  IconTaskList,
} from "@brenger/react";
import cn from "classnames";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth, useTranslation } from "../../hooks";
import { Routes } from "../../utils";
import "./menu.scss";

interface Props {
  toggleExpand(): void;
}

interface MenuItem {
  path: string[];
  label: string;
  icon: React.ReactNode;
}

export const Menu: React.FC<Props> = ({ toggleExpand }) => {
  const auth = useAuth();
  const { t } = useTranslation();

  const menuItems: MenuItem[] = [
    {
      label: t((d) => d.app.main_menu.new),
      path: [Routes.new.home()],
      icon: <IconSearch />,
    },
    {
      label: t((d) => d.app.main_menu.transports),
      path: [Routes.transports.home({ type: "planning" }), Routes.transports.home({ type: "delivered" })],
      icon: <IconTaskList style={{ fontSize: "1.3em", margin: "-0.15em -0.1em 0 -0.15em" }} />,
    },
    {
      label: t((d) => d.app.main_menu.day_routes),
      path: [Routes.dayroutes.home()],
      icon: <IconMaps />,
    },
    {
      label: t((d) => d.app.main_menu.support),
      path: [Routes.support.home()],
      icon: <IconChat />,
    },
    {
      label: t((d) => d.app.main_menu.more),
      path: [Routes.more.home()],
      icon: <IconHorizontalDots style={{ fontSize: "1.3em", margin: "-0.15em -0.1em 0 -0.15em" }} />,
    },
  ];

  if (!auth.isAccountAdmin) {
    menuItems.shift();
  }
  const { pathname } = useLocation();

  const isTour = pathname.includes("app-tour");
  const isLogin = pathname.includes("login");

  React.useEffect(() => {
    /**
     * This class is to prevent the main menu is shown on deeper pages
     * I considered to make this "app state", but because it is only css that should change,
     * I thought this was the most efficient.
     * FIXME: day-route should not show menu when stop or deeper navigated
     */
    if (pathname.search(/(new|(transports\/.+\/)|(day-route\/.+\/)|support|more)\/.+/) !== -1 || isLogin || isTour) {
      document.body.classList.add("app-menu--mobile--hidden");
      if (isLogin || isTour) {
        document.body.classList.add("app-menu--hidden");
      }
    } else {
      document.body.classList.remove("app-menu--mobile--hidden", "app-menu--hidden");
    }
  }, [pathname]);

  return (
    <div className="app-menu">
      <div className="app-menu--desktop">
        <div className="app-menu--branding">
          <div className="app-menu--branding--icon">
            <div className="app-menu--branding--icon--square">
              <IconBrengerB style={{ fontSize: "1.37044rem" }} />
            </div>
          </div>
          <div className="app-menu--branding--logo">
            <div className="app-menu--branding--logo--inner">
              <IconBrengerLogo style={{ fontSize: "1.43rem" }} />
              <div style={{ opacity: ".5", fontSize: "0.9em", lineHeight: 1 }}>voor koeriers</div>
            </div>
          </div>
        </div>
        {menuItems.map((item, i) => {
          const isActive = item.path.some((path) => pathname?.includes(path));
          return (
            <Link key={i} to={item.path[0]}>
              <div
                className={cn("app-menu--item", {
                  "app-menu--item--active": isActive,
                })}
              >
                <div className="app-menu--item--icon">{item.icon}</div>{" "}
                <div className="app-menu--item--inner">
                  <div className="app-menu--item--label whitespace-nowrap">{item.label}</div>
                </div>
              </div>
            </Link>
          );
        })}
        <button className={"app-menu--toggle"} type="button" onClick={toggleExpand}>
          <IconDoubleLeftCarret />
        </button>
      </div>
      <div className="app-menu--mobile">
        {menuItems.map((item, i) => {
          return (
            <Link key={i} to={item.path[0]}>
              <div
                className={cn("app-menu--mobile--item", {
                  "app-menu--item--active": item.path.some((path) => pathname?.includes(path)),
                })}
              >
                <div className="app-menu--mobile--item--icon">{item.icon}</div>{" "}
                <div className="app-menu--mobile--item--label">{item.label}</div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
