/**
 * ALERT: New router in the making with IonicRouter
 * The Enum way are classic routes.
 * If the feature you are adding should be supported in the old routing system then add it the enum way
 * If it only needs to live in the new system add it to IonicRoutes.
 */

import {
  DayRouteAddParams,
  DayRouteParams,
  DayRouteStopParams,
  NewJobParams,
  NewStopParams,
  TransportsJobParams,
  TransportsListParams,
  TransportsStopParams,
} from "./typings";

const dayRouteHome = (): string => {
  return "/day-route";
};

export const Routes = {
  more: {
    home: (): string => {
      return "/more";
    },
    inbox: (tjalId?: string): string => {
      if (tjalId) {
        return `/more/inbox/${tjalId}`;
      }
      return `/more/inbox`;
    },
    dashboard: (): string => {
      return "/more/dashboard";
    },
    reviews: (): string => {
      return "/more/reviews";
    },
    brengerId: (): string => {
      return "/more/brenger-id";
    },
    profile: (): string => {
      return "/more/profile";
    },
    vehicles: (id?: string): string => {
      if (id === "create") {
        return `/more/vehicles-create`;
      }
      if (id) {
        return `/more/vehicles/${id}`;
      }
      return `/more/vehicles`;
    },
    couriers: (): string => {
      return "/more/couriers";
    },
    favouriteRoutes: (): string => {
      return "/more/favourite-routes";
    },
    appSettings: (): string => {
      return "/more/app-settings";
    },
    forget: (): string => {
      return "/more/forget";
    },
  },
  support: {
    home: (): string => {
      return "/support";
    },
  },
  new: {
    home: (): string => {
      return "/new";
    },
    job: ({ job_id }: NewJobParams): string => {
      return `/new/${job_id}`;
    },
    claim: ({ job_id }: NewJobParams): string => {
      return `/new/${job_id}/claim`;
    },
    stop: ({ job_id, stop_id }: NewStopParams): string => {
      return `/new/${job_id}/stops/${stop_id}`;
    },
  },
  transports: {
    home: ({
      type,
      includeNps,
    }: TransportsListParams & {
      /**
       * This means we include the component on mount.
       * For logic around showing the form, check "NpsForm" component.
       */
      includeNps?: boolean;
    }): string => {
      return `/transports/${type}${includeNps ? `?${SHOW_NPS_PARAM}=1` : ""}`;
    },
    job: ({ type, job_id }: TransportsJobParams): string => {
      return `/transports/${type}/${job_id}`;
    },
    stop: ({ type, job_id, stop_id }: TransportsStopParams): string => {
      return `/transports/${type}/${job_id}/stops/${stop_id}`;
    },
    confirmStop: ({ type, job_id, stop_id }: TransportsStopParams): string => {
      return `/transports/${type}/${job_id}/stops/${stop_id}/confirm`;
    },
    add: ({ type, job_id, stop_id }: TransportsStopParams): string => {
      return `/transports/${type}/${job_id}/stops/${stop_id}/confirm`;
    },
  },
  dayroutes: {
    home: (): string => {
      return dayRouteHome();
    },
    details: ({ date, user_id }: DayRouteParams): string => {
      return `/day-route/${date}/${user_id}`;
    },
    add: ({ date, user_id, type }: DayRouteAddParams): string => {
      if (type) {
        return `/day-route/${date}/${user_id}/add/${type}`;
      }
      return `/day-route/${date}/${user_id}/add`;
    },
    stop: ({ date, user_id, stop_id }: DayRouteStopParams): string => {
      return `/day-route/${date}/${user_id}/activity/${stop_id}`;
    },
    confirmStop: ({ date, user_id, stop_id }: DayRouteStopParams): string => {
      return `/day-route/${date}/${user_id}/activity/${stop_id}/confirm`;
    },
  },
  auth: {
    login: (): string => {
      return "/login";
    },
    logout: (): string => {
      return "/logout";
    },
  },
  appTour: {
    home: (): string => {
      return "/app-tour";
    },
  },
  default: (): string => {
    return dayRouteHome();
  },
};

export const SHOW_NPS_PARAM = "show-nps";
export const USE_HISTORY_BACK_PARAM = "use-history-back";

/**
 * @deprecated Old routes not in use by app, only in use by mailer
 */
export enum OldRoutes {
  SEARCH_JOB_DETAILS = "/search/:tj_id",
  PLANNING_JOB_DETAILS = "/planning/:tjal_id",
  DELIVERED_JOB_DETAILS = "/delivered/:tjal_id",
  INBOX_DETAILS = "/inbox/:tjal_id",
  USER_SETTINGS = "/settings", // more app settings
  USER_ROUTES = "/profile/routes", // fav routes
}
