import { Button, IconEye, InputText, Label, Message, Spacer, useKeyPress } from "@brenger/react";
import { useMutation } from "@tanstack/react-query";
import cn from "classnames";
import * as React from "react";
import { useHistory } from "react-router-dom";

import { ExternalLink, LanguageSelect } from "../../components";
import { Config, LOCALE_TEMPLATE } from "../../config";
import { useDeviceInfo, useForm, useHasSeenTour, useTranslation } from "../../hooks";
import { CacheKey, coreClient, fetchSandboxToken, getCustomerAppUrl, isSandbox, logger, Routes } from "../../utils";

import { SavePassword } from "capacitor-ios-autofill-save-password";
import favicon from "../../assets/images/favicon-360.png";
import { useCache } from "../../hooks";
import { Content, Section } from "../../layout";

export const Login: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { value: hasSeenTour } = useHasSeenTour();
  // NOTE: the referrer key is set in App.tsx - look there for more context.
  // const location = useLocation<{ referrer?: string }>();
  const enter = useKeyPress("Enter");
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const deviceInfo = useDeviceInfo();
  const history = useHistory();
  const cache = useCache();
  const [isLoading, setIsLoading] = React.useState(false);

  const onSuccess = async (): Promise<void> => {
    // To save / update credentials to the password manager, trigger this function.
    // If nothing new to add it moves along else it shows a prompt
    // see: https://capacitorjs.com/docs/guides/autofill-credentials
    if (deviceInfo?.platform === "ios") {
      await SavePassword.promptDialog({
        username: form.data.email.value,
        password: form.data.password.value,
      });
    }

    try {
      // Manually update user data
      // Retrieve user info
      const user = await coreClient.users.retrieveCurrentUser();
      // set result in query cache
      cache.queryClient.setQueryData([CacheKey.RETRIEVE_CURRENT_USER], user);
      setIsLoading(false);
      form.reset();
      // redirect user to correct path
      if (hasSeenTour) {
        history.push(Routes.default());
        return;
      }
      history.push(Routes.appTour.home());
    } catch (e) {
      logger.error("Could not log in", e);
    }
  };

  const login = useMutation(coreClient.users.login, { onSuccess, onError: () => setIsLoading(false) });

  const form = useForm({
    initialState: { email: "", password: "" },
    validators: { email: (val) => !val, password: (val) => !val },
  });

  const submitForm = async (): Promise<void> => {
    setIsLoading(true);

    let token: string | undefined = undefined;
    if (isSandbox()) {
      const authSandbox = await fetchSandboxToken({ email: form.data.email.value, password: form.data.password.value });
      token = authSandbox?.token;
    }
    login.mutate({
      email: form.data.email.value,
      password: form.data.password.value,
      token,
    });
  };

  /**
   * Handle "Enter" key press.
   * This allows users to login by hitting the enter button at any point on this page.
   */
  React.useEffect(() => {
    if (enter && !form.hasErrors) submitForm();
  }, [enter]);

  return (
    <Content style="primary">
      <Section type="single-details" isTop={true} style={{ maxWidth: "28rem" }}>
        <div className={cn("flex", "justify-center")}>
          <img src={favicon} style={{ maxWidth: "9rem", maxHeight: "9rem" }} />
        </div>
        <Spacer h={4} />
        <Label text={t((d) => d.login.labels.email)} className={cn("text-white")}>
          <InputText
            autoComplete="email"
            className={cn("w-full")}
            value={form.data.email.value}
            onChange={(email) => void form.set({ email: email.trim() })}
          />
        </Label>
        <Spacer h={4} />
        <Label text={t((d) => d.login.labels.password)} className={cn("text-white")}>
          <div className={cn("relative")}>
            <InputText
              autoComplete="password"
              style={{ paddingRight: "3.4rem" }}
              className={cn("w-full")}
              value={form.data.password.value}
              type={isPasswordVisible ? "text" : "password"}
              onChange={(password) => form.set({ password: password.trim() })}
            />
            <button
              style={{ height: 42 }}
              className={cn("absolute", "right-0", "top-0", "px-3", {
                "opacity-100": isPasswordVisible,
                "opacity-50": !isPasswordVisible,
              })}
            >
              <IconEye className={cn("w-8", "h-8")} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
            </button>
          </div>
        </Label>
        <Button
          buttonType="secondary"
          disabled={form.hasErrors}
          loading={isLoading}
          className={cn("mt-6", "w-full")}
          onClick={submitForm}
        >
          {t((d) => d.login.labels.submit)}
        </Button>
        {login.isError && (
          <Message className={cn("mt-4")} type="error">
            {/* For security reasons, always show the same error message when login fails. */}
            {t((d) => d.login.error_message)}
          </Message>
        )}
        <div className={cn("flex", "items-center", "justify-center", "flex-wrap", "w-full", "text-center", "mt-4")}>
          <ExternalLink
            className={cn("text-white", "underline")}
            url={`${getCustomerAppUrl(i18n.locale)}/customer/password_reset`}
          >
            {t((d) => d.login.reset_password)}
          </ExternalLink>
          <span className={cn("mx-2", "text-white")}>|</span>
          <ExternalLink
            className={cn("text-white", "underline")}
            url={Config.DRIVER_SIGN_UP_URL.replace(LOCALE_TEMPLATE, i18n.locale)}
          >
            {t((d) => d.login.register_cta)}
          </ExternalLink>
        </div>
        <div style={{ maxWidth: 110 }} className={cn("mx-auto", "mt-4")}>
          <LanguageSelect />
        </div>
      </Section>
    </Content>
  );
};
