import { AppTrackingStatus, AppTrackingTransparency } from "capacitor-plugin-app-tracking-transparency";
import * as React from "react";
import { Config } from "../config";
import { useAuth } from "./useAuth";
import { useDeviceInfo } from "./useDeviceInfo";
import { SortByField } from "../store/search/types";
import { logger } from "../utils";

export interface JobFunnelEvent {
  event: "Job funnel";
  timeStamp: string;
  action: "impression" | "claim";
  id: string;
  listPosition: number;
  listLength: number | null;
  score: number | null;
  isDefaultScore: boolean | null;
  totalJobsCount: number | null;
  activeFilter: string;
  sortByField: SortByField;
  courierId: string | null;
}

export interface UserData {
  user_id: string;
}

export type DataLayerData = JobFunnelEvent | UserData;

const scriptId = "BRENGER_GTM";
interface PromptArgs {
  callback?(): void;
  onAuthorized?(): void;
  onNotAuthorized?(): void;
}

export interface UseTracking {
  isInitialized: boolean;
  /**
   * Trigger events in GA
   */
  sendEvent(data: DataLayerData): void;
  /**
   * Status of authorization
   * - Denied: not for this app
   * - Restricted: global setting on ios to prevent tracking
   * See Apple docs: https://developer.apple.com/documentation/apptrackingtransparency/attrackingmanager/authorizationstatus
   */
  trackStatus: AppTrackingStatus | null;
  isLoading: boolean;
  prompt(args?: PromptArgs): void;
}

export function useTracking(): UseTracking {
  const [trackStatus, setTrackStatus] = React.useState<AppTrackingStatus | null>(null);
  const [isInitialized, setIsInitialized] = React.useState(!!document.getElementById(scriptId));
  const deviceInfo = useDeviceInfo();
  const platform = deviceInfo?.platform;
  const auth = useAuth();

  /**
   * Handle permission checks
   */
  const checkTrackingPermission = async (): Promise<void> => {
    const ios = platform === "ios";
    // for any none iOS platform we can proceed
    if (!ios && platform) {
      await setTrackStatus("authorized");
      return;
    }
    // for iOS we need to check permission first
    if (ios && platform) {
      const appTrackingResp = await AppTrackingTransparency.getStatus();
      await setTrackStatus(appTrackingResp.status);
    }
  };

  const prompt = async (args?: PromptArgs): Promise<void> => {
    // see apple docs: https://developer.apple.com/documentation/apptrackingtransparency/attrackingmanager/authorizationstatus
    const appTrackingResp = await AppTrackingTransparency.requestPermission();
    await setTrackStatus(appTrackingResp.status);
    if (appTrackingResp.status !== "authorized") {
      await args?.onNotAuthorized?.();
    }
    if (appTrackingResp.status === "authorized") {
      await args?.onAuthorized?.();
    }
    await args?.callback?.();
  };
  /**
   *
   * Load google tag manager
   */
  const initGoogle = async (): Promise<void> => {
    if (!Config.GTM_ID || !Config.GTM_ENV_PARAMS) {
      return;
    }
    if (document.getElementById(scriptId)) {
      const gtm = document.createElement("script");
      gtm.id = scriptId;
      gtm.innerHTML = `
      (function(w,d,s,l,i,e){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl+e+'&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${Config.GTM_ID}', '${Config.GTM_ENV_PARAMS}');
      `;
      document.head.appendChild(gtm);
    }
    setIsInitialized(true);
  };

  // Check permission on mount
  React.useEffect(() => {
    if (!platform) return;
    checkTrackingPermission();
  }, [platform]);

  /**
   * Handle authorization change, initialize and send user id
   */
  React.useEffect(() => {
    if (trackStatus === "authorized" && !isInitialized) {
      initGoogle();
    }
    if (isInitialized && auth.userId) {
      sendEvent({
        user_id: auth.userId,
      });
    }
  }, [trackStatus, isInitialized, auth.userId]);

  const sendEvent = (data: DataLayerData): void => {
    logger.dev(`GA Event`, data);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  };

  return {
    trackStatus,
    isInitialized,
    sendEvent,
    isLoading: trackStatus === null,
    prompt,
  };
}
