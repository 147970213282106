import { addDays, isAfter } from "date-fns";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  useAppEffects,
  useAppInfo,
  useAuth,
  useDeviceInfo,
  useHasSeenTour,
  useNativeAppVersion,
  usePersistedState,
} from "../../hooks";
import { useNativeAppEffects } from "../../hooks/useNativeAppEffects";
import { PersistedStorageKey, Routes } from "../../utils";
import { AppUpdatePrimer } from "../primers/AppUpdatePrimer";
import { Content } from "../../layout";

/**
 * Action/Messages that should block further app access should live here
 * Plus basic inits of services
 */
export const AppInitGate: React.FC = ({ children }) => {
  const [showOptionalUpdateMessage, setShowOptionalUpdateMessage] = usePersistedState<string | null>({
    initialState: null,
    defaultState: null,
    version: 1,
    key: PersistedStorageKey.HAS_SEEN_OPTIONAL_APP_UPDATE,
  });
  const { value: hasSeenTour, isInitialized: isTourValueLoading } = useHasSeenTour();

  // Bunch of effects that need to init
  useAppEffects();
  useNativeAppEffects();

  /** Then check for log in and redirect */
  const auth = useAuth();
  const history = useHistory();

  React.useEffect(() => {
    if (isTourValueLoading) return;
    // Makes sure that everyone sees the tour
    if (auth.isLoggedIn && !hasSeenTour) {
      history.push(Routes.appTour.home());
      return;
    }
    if (!auth.isLoggedIn) {
      history.push(Routes.auth.login());
    }
    // we want this to happen once on mount, so "isLoggedIn" should not be a dep
  }, [isTourValueLoading]);

  // Effects to show version update is needed
  const appInfo = useAppInfo();
  const deviceInfo = useDeviceInfo();
  const nativeAppVersion = useNativeAppVersion({ version: appInfo?.version, platform: deviceInfo?.platform });

  /**
   * If the app version is not supported or if there is an update available
   */
  if (
    !nativeAppVersion.isSupported ||
    (nativeAppVersion.isUpdateAvailable &&
      (!showOptionalUpdateMessage || isAfter(new Date(), new Date(showOptionalUpdateMessage))))
  ) {
    return (
      <div className="h-screen w-screen">
        <Content style="primary" className="text-white">
          <div className=" pt-8">
            <AppUpdatePrimer
              supported={nativeAppVersion.isSupported}
              dismiss={() => setShowOptionalUpdateMessage(addDays(new Date(), 1).toISOString())}
            />
          </div>
        </Content>
      </div>
    );
  }

  return <>{children}</>;
};
